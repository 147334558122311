import { Box, TextField } from "@mui/material";
import React from "react";

import { AddressInput, ValueOf } from "../types";

type Props = {
  address: Partial<AddressInput>;
  handleAddressChange: (
    arg0: keyof AddressInput,
    arg1: ValueOf<AddressInput>,
  ) => void;
};
export default function AddressForm({ address, handleAddressChange }: Props) {
  return (
    <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
      <TextField
        label="Address Line 1"
        variant="outlined"
        value={address?.addressLine1 || ""}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleAddressChange("addressLine1", event.target.value);
        }}
        fullWidth
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
          marginTop: "1rem",
        }}
      >
        <TextField
          label="Address Line 2"
          variant="outlined"
          value={address?.addressLine2 || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleAddressChange("addressLine2", event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="Address Line 3"
          variant="outlined"
          value={address?.addressLine3 || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleAddressChange("addressLine3", event.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="City"
          variant="outlined"
          value={address?.city || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleAddressChange("city", event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="State"
          variant="outlined"
          value={address?.state || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleAddressChange("state", event.target.value);
          }}
          fullWidth
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <TextField
          label="Postal Code"
          variant="outlined"
          value={address?.postalCode || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleAddressChange("postalCode", event.target.value);
          }}
          fullWidth
        />
        <TextField
          label="Country"
          variant="outlined"
          value={address?.country || ""}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleAddressChange("country", event.target.value);
          }}
          fullWidth
        />
      </Box>
    </Box>
  );
}
